.maplibregl-ctrl-top-right {
  top: 20px !important;
  right: 140px !important;
}

@media (max-width: 600px) {
  .maplibregl-ctrl-top-right {
    top: 20px !important;
    right: 20px !important;
  }
}